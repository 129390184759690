<template>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <CToaster placement="top-end" visible>
    <CToast v-for="(toast, index) in toasts">
      <CToastHeader closeButton>
      <span class="me-auto fw-bold">{{toast.title}}</span>
      </CToastHeader>
    </CToast>
  </CToaster>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left">
        <span v-if="staff_nickname">Hello, {{ staff_nickname }}</span>
      </div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span style="color:green;">預約表</span>
          </div>
        </div>
      </div>
      <div class="col-4 text-right">
        <span style="font-size: 13px;">Version {{ version }}&nbsp;</span>
      </div>
    </div>

    <div class="row">
      <div class="col-4 text-left">
        
      </div>
      <div class="col-4 text-center">
        <span>注意 / 黑名單會員</span>
      </div>
      <div class="col-2 text-right">
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-warning"
          @click="get_api()"
          >
            <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
            <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
              <span style="color: red; font-size: 1rem !important;">!</span>
            </b-badge>
        </b-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-3">
        <template v-if="callteam">
          <b-tabs active-nav-item-class="text-success">
          <b-tab active @click="filtersourceList.sourceList = 2">
            <template #title>
              <strong>黑名單會員</strong>
            </template>
          </b-tab>
        </b-tabs>
        </template>
        <template v-else>
          <b-tabs active-nav-item-class="text-success">
            <b-tab active @click="filtersourceList.sourceList = 1" :disabled="callteam == 'true' ? true : true">
              <template #title>
                <strong>注意會員</strong>
              </template>
            </b-tab>
            <b-tab @click="filtersourceList.sourceList = 2">
              <template #title>
                <strong>黑名單會員</strong>
              </template>
            </b-tab>
          </b-tabs>
        </template>
      </div>
      <div class="col-3 text-rifht">
        
      </div>
      <div class="col-3 text-right">
        <CFormInput
          v-model="filtersourceList.freetext"
          type="text"
          placeholder="搜尋會員編號"
        />
      </div>
      <div class="col-3 text-center">
        <span>新增</span>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <table-lite
          :is-slot-mode="true"
          :columns="getField"
          :rows="dataSourceList"
          :is-hide-paging="true"
        >
          <template #pos_vipcode="data">
            {{ data.value.pos_vipcode }}
          </template>
          <template #reason="data">
            <Multiselect
              v-model="data.value.reason"
              mode="single"
              :close-on-select="true"
              :searchable="false"
              :create-option="false"
              :options="blockreason_list"
              :allow-empty="false"
              :canClear="false"
              valueProp="id"
              trackBy="name"
              label="name"
              @input="inline_edit(data.value)"
            />
          </template>
          <template #age="data">
            <CFormInput
              id="age"
              ref="age"
              v-model="data.value.age"
              size="sm"
              type="text"
              @input="inline_edit(data.value)"
            />
          </template>
          <template #remark="data">
            <CFormInput
              id="member_no"
              ref="member_no"
              v-model="data.value.remark"
              size="sm"
              type="text"
              @input="inline_edit(data.value)"
            />
          </template>
          <template #color="data">
            <Multiselect
              v-model="data.value.color"
              mode="single"
              :close-on-select="true"
              :searchable="false"
              :create-option="false"
              :options="color_list"
              :allow-empty="false"
              :canClear="false"
              valueProp="color"
              trackBy="color"
              @input="inline_edit(data.value)"
            >
              <template v-slot:singlelabel="{ value }">
                <div class="multiselect-single-label">
                  <span :style="{ 'background-color': value.color}" class="dot"></span>&nbsp;
                  <span>{{value.desc}}</span>
                </div>
              </template>
              <template v-slot:option="{ option }">
                <span :style="{ 'background-color': option.color}" class="dot"></span>&nbsp;
                <span>{{option.desc}}</span>
              </template>
            </Multiselect>
          </template>
          <template #source_from="data">
            <span v-if="data.value.source_from == 1">{{ getlistitem('staff', data.value.created_by) }}(分店)</span>
            <span v-if="data.value.source_from == 2">{{ getlistitem('staff', data.value.created_by) }}(Call Team)</span>
          </template>
          <template #approved_by="data">
            <span>{{ getlistitem('staff', data.value.approved_by) }}</span>
          </template>
          <template #action="data">
            <b-button v-if="data.value.datachange" variant="success" @click="action_auth('submit', data.value)">
              <span>{{ $t('common.button.update') }}</span>
            </b-button>
          </template>
        </table-lite>
      </div>
      <div class="col-3 text-center">
        <div class="row">
          <div class="col-12">
            會員編號
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CFormInput
              id="member_no"
              ref="member_no"
              v-model="eventInfo.pos_vipcode"
              size="sm"
              type="text"
            />
          </div>
        </div>
        <div v-show='filtersourceList.sourceList == 2'>
          <div class="row">
            <div class="col-12">
              黑名單原因
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Multiselect
                v-model="eventInfo.reason"
                mode="single"
                :close-on-select="true"
                :searchable="false"
                :create-option="false"
                :options="blockreason_list"
                :allow-empty="false"
                :canClear="false"
                valueProp="id"
                trackBy="name"
                label="name"
              />
            </div>
          </div>
          <div v-show="eventInfo.reason == '2'">
            <div class="row">
              <div class="col-12">
                年齡
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <CFormInput
                  id="age"
                  ref="age"
                  v-model="eventInfo.age"
                  size="sm"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            備註
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CFormInput
              id="member_no"
              ref="member_no"
              v-model="eventInfo.remark"
              size="sm"
              type="text"
            />
          </div>
        </div>
        <div v-show='filtersourceList.sourceList == 1'>
          <div class="row">
            <div class="col-12">
              顯示顏色
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Multiselect
                v-model="eventInfo.color"
                mode="single"
                :close-on-select="true"
                :searchable="false"
                :create-option="false"
                :options="color_list"
                :allow-empty="false"
                :canClear="false"
                valueProp="color"
                trackBy="color"
              >
                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    <span :style="{ 'background-color': value.color}" class="dot"></span>&nbsp;
                    <span>{{value.desc}}</span>
                  </div>
                </template>
                <template v-slot:option="{ option }">
                  <span :style="{ 'background-color': option.color}" class="dot"></span>&nbsp;
                  <span>{{option.desc}}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div v-show='filtersourceList.sourceList == 2'>
          <div class="row">
            <div class="col-12">
              Approved By
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-button v-show="!eventInfo.approved_by" variant="success" @click="action_auth('approve', eventInfo)">
                <span>批核</span>
              </b-button>
              <span v-show="eventInfo.approved_by">{{ getlistitem('staff', eventInfo.approved_by) }}</span>
            </div>
          </div>
        </div>
        <br>
        <div class="row" v-show="filtersourceList.sourceList == 1 || eventInfo.approved_by">
          <div class="col-12">
            <b-button variant="success" @click="before_submit('submit')">
              <span>{{ $t('common.button.submit') }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  components: {
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      callteam: false,
      modalShow: false,
      need_update: false,
      version: '',
      version_update: false,
      advisor: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      staff_nickname: '',
      staff_list: [],
      action_by: '',
      action_type: '',
      advisor_admin_role: false,
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      vaild_member: false,
      role: [],
      permission: [],
      member_no: '',
      show: false,
      sourceList: 'active',
      filtersourceList: {
        booking_date: '',
        sourceList: 1,
        freetext: '',
        advisor: '',
        checkin: '1',
      },
      lang: '',
      eventAction: '',
      eventInfo: {},
      dataSource: [],
      edit_mode: false,
      toasts: [],
      color_list: [
        {
          color: '#df526c',
          desc: '投訴客',
        },
        {
          color: '#4c5cd1',
          desc: '唔可以銷售',
        },
        {
          color: 'ORANGE',
          desc: '有特別位置不能做',
        },
        {
          color: '#029d74',
          desc: '唔等得，要即刻入房',
        },
        {
          color: '#c43089',
          desc: '唔接受指定理療師',
        },
        {
          color: '#865632',
          desc: '不建議做電流療程',
        },
      ],
      blockreason_list: [
        {
          id: '1',
          name: '有指定病情不能做',
        },
        {
          id: '2',
          name: '超出及低於的年齡層',
        },
        {
          id: '3',
          name: '其他',
        },
      ],
    }
  },
  computed: {
    dataSourceList: function () {
      return this.get_dataSourceList(this.filtersourceList.sourceList)
    },
    getField: function () {
      var fields = []
      if(this.filtersourceList.sourceList == '1'){
        fields = [
          {
            label: '會員編號',
            field: 'pos_vipcode',
            sortable: false,
            isKey: false,
            width: "14%",
          },
          {
            label: '備註',
            field: 'remark',
            sortable: false,
            isKey: false,
            width: "41%",
          },
          {
            label: '顏色',
            field: 'color',
            sortable: false,
            isKey: false,
            width: "35%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "20%",
          }
        ]
      }else{
        fields = [
          {
            label: '會員編號',
            field: 'pos_vipcode',
            sortable: false,
            isKey: false,
            width: "12%",
          },
          {
            label: '原因',
            field: 'reason',
            sortable: false,
            isKey: false,
            width: "28%",
          },
          {
            label: '年齡',
            field: 'age',
            sortable: false,
            isKey: false,
            width: "8%",
          },
          {
            label: '備註',
            field: 'remark',
            sortable: false,
            isKey: false,
            width: "26%",
          },
          {
            label: '建立者',
            field: 'source_from',
            sortable: false,
            isKey: false,
            width: "12%",
          },
          {
            label: '批核同事',
            field: 'approved_by',
            sortable: false,
            isKey: false,
            width: "12%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "20%",
          }
        ]
      }
      return fields
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = 'Member Block List'
      }
    },
    'eventInfo.pos_vipcode'(){
        if(this.eventInfo.pos_vipcode){
          this.eventInfo.pos_vipcode = this.eventInfo.pos_vipcode.toUpperCase()
        }
      },
  },
  created() {
    const self = this
    self.lang = localStorage.getItem('locale')
    setTimeout(function () {
      document.getElementById('confirmModal_open').click()
      setTimeout(function () {
        document.getElementById('staff_code').focus()
      }, 500)
    }, 300)
    self.getrealip()
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
  },
  methods: {
    getrealip(){
      const self = this
      this.axios
        .get('https://record.ankh.com.hk/api/getip', {})
        .then(function (res) {
          const ip = res.data.ip
          if(ip == '103.252.76.54' || ip == '103.247.166.198' || ip == '223.255.185.46' || ip == '202.130.127.38' || ip == '183.178.147.150'){
            self.allowed = true
          }
        })
        .catch((error) => { 
          console.error(error)
          self.allowed = true
        })
    },
    get_dataSourceList(type) {
      const self = this
      let list = []
      if(type == '1'){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.type == 1
        })
      }else{
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.type == 2
        })
      }
      if(this.filtersourceList.freetext){
        list = list.filter(function (item) {
          return item?.pos_vipcode?.toUpperCase().includes(self.filtersourceList.freetext.toUpperCase())
        })
      }
      return list
    },
    initialize() {
      const self = this
      self.eventInfo = {
        id: 0,
        pos_vipcode: '',
        reason: '',
        age: '',
        remark: '',
        color: '',
        approved_by: '',
        status: '',
      }
      this.formData = new FormData()
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.staff_list = response.data.staff_list
        setTimeout(function () {
          self.get_api()
        }, 500)
      })
    },
    get_api() {
      const self = this
      self.show = true
      self.dataSource = []
      this.formData = new FormData()
      this.axios
      .post('/member/blockList', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.dataSource = response.data.data
      })
      self.show = false
    },
    inline_edit(data){
      data.datachange = true
    },
    submit() {
      const self = this
      self.update_api()
    },
    update_api() {
      const self = this
      self.edit_mode = false
      self.show = true
      var temp_obj = {}
      temp_obj.id = self.eventInfo.id
      temp_obj.pos_vipcode = self.eventInfo.pos_vipcode
      temp_obj.type = self.filtersourceList.sourceList
      temp_obj.source_from = 1
      if(self.callteam){
        temp_obj.source_from = 2
      }
      temp_obj.reason = self.eventInfo.reason
      temp_obj.age = self.eventInfo.age
      temp_obj.remark = self.eventInfo.remark
      temp_obj.color = self.eventInfo.color
      temp_obj.color_desc = ''
      temp_obj.status = self.eventInfo.status
      temp_obj.action_by = self.action_by
      temp_obj.approved_by = self.eventInfo.approved_by
      if(self.eventInfo.color){
        var list = this.color_list.filter(function (item) {
          return item.color == self.eventInfo.color
        })
        temp_obj.color_desc = list[0].desc
      }
      this.axios
      .post('/member/blockList/createorupdate', {
        data: temp_obj,
      })
      .then(function () {
        self.show = false
        self.toasts.push({
          title: '成功更新',
          content: ''
        })
        self.initialize()
      })
    },
    action_request(){
      document.getElementById('requestModal_open').click()
    },
    member_check(info) {
      const self = this
      if (info.member_no) {
        self.vaild_member = true
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.member_no = response.data.member.memberId
            info.name = response.data.member.cardName
            if(!info.gender){
              info.gender = response.data.member.gender
            }
          }else{
            self.vaild_member = false
            info.name = "找不到相關會員編號"
          }
        })
      }
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    before_submit(type){
      const self = this
      self.eventInfo.id = 0
      self.eventInfo.status = 1
      self.action_auth(type, self.eventInfo)
    },
    action_auth(type, data) {
      const self = this
      self.action_type = type
      self.eventInfo = data
      self.staff_code = ''
      data.datachange = false
      setTimeout(function () {
        document.getElementById('confirmModal_open').click()
        setTimeout(function () {
          document.getElementById('staff_code').focus()
        }, 500)
      }, 300)
    },
    getlistitem(list, id) {
      const self = this
      var nickname = ""
      if(list == "staff"){
        var selected = self.staff_list.find(i => i.id == id)
        if(selected){
          return selected.nickname
        }
      }
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.staff_nickname = staff_info[0]['nickname']
                self.role = response.data.role
                var ip_role = self.role.findIndex(el => el == '20')
                var callteam = self.role.findIndex(el => el == '18')
                if(callteam >= 0){
                  self.callteam = true
                  self.filtersourceList.sourceList = 2
                }
                if(self.allowed == true || ip_role >= 0){
                  self.vaild_staff_code = true
                  self.permission = response.data.permission
                  document.getElementById('confirmModal_close').click()
                  self.staff_code_checked = true
                  self.show = true
                  self.initialize()
                }else{
                  self.vaild_staff_code = false
                }              
              }else {
                self.vaild_staff_code = false
              }
            }else{
              if (staff_info.length > 0) {
                self.action_by = staff_info[0]['id']
                document.getElementById('confirmModal_close').click()
                if(self.action_type == 'submit'){
                  self.submit()
                }else if(self.action_type == 'approve'){
                  self.eventInfo.approved_by = staff_info[0]['id']
                }
                self.vaild_staff_code = true
              }else {
                self.vaild_staff_code = false
              }
            }
          })
        document.getElementById('staff_code').blur()
      }
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@media screen and (max-width: 1080px) {
  .body {
    font-size: 16px !important;
  }
}

.modal {
  top: -20px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
}

.genderM {
  border-left: 10px solid #a9e7f3 !important;
  border-top: 10px solid #a9e7f3 !important;
  border-bottom: 10px solid #a9e7f3 !important;
}

.genderF {
  border-left: 10px solid #ffd5df !important;
  border-top: 10px solid #ffd5df !important;
  border-bottom: 10px solid #ffd5df !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.genderFBG{
  background-color: #ffd5df !important;
}

.newJoin {
  background-color: #ebeb38 !important;
}

.CallTeam {
  background-color: #50d43a;
}

.WTSTeam {
  background-color: #ff9f55;
}

.vtl-tbody-td{
  padding: 0.3rem !important;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.drag {
  box-sizing: border-box;
  padding: 3px 5px;
  margin-bottom: 5px;
}

.offcanvas-width{
  width: 550px !important;
}

.offcanvas-width2{
  width: 700px !important;
}

.offcanvas-width3{
  width: 250px !important;
}

.offcanvas-width4{
  width: 550px !important;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
.without_therapist {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update2 {
  color: white;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.tab-content {
  padding: 0px !important; 
}

.nav-link {
  color: #525c6a;
}

.nav-tabs {
  border-bottom: 0px solid;
}

.multiselect-dropdown {
  height: 25rem !important;
}

.offcanvas-start {
  z-index: 9999 !important;
}

.vtl-table {
  max-height: calc(100vh - 250px) !important;
}

.toast {
  background-color: #1fb05fd9 !important;
}

.toast-header {
  color: #000000 !important;
}

.vtl-table-responsive-sm {
  display: revert !important;
}

.vtl-table-responsive {
  display: revert !important;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
</style>